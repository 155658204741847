import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Input, Form, Button } from 'reactstrap';
import List from './List';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Spinners from 'Components/Common/Spinner';
import { applyMultipleFilters, getAllMentors, getMatchedMentors, fetchSuggestedMentors, fetchDepartmentsAndDesignations, fetchPersonalExperiences } from 'slices/mentor/thunk';
import Select from "react-select";
import { getPublicConfig } from "slices/thunk";
import { isEmpty } from 'lodash';
import Paginations from "Components/Common/Pagination";
import { useLocation } from "react-router-dom";


type Person = {
  id: string;
  matchPercentage?: number;
  avatarUrl?: string;
  firstName: string;
  lastName: string;
  department?: string;
  title?: string;
  location?: string;
  skills?: string[];
};

type Mentor = Person;
type Mentee = Person;

type FilteredMentorList = {
  mentors: Mentor[];
  menteesSuggestions: Mentee[];
  mentorSuggestions: Mentor[];
  total: number;
  page: number;
  perPage: number;
  totalPages: number;
};


const MentorList = (props: any) => {
  document.title = "Mentors List";

  const dispatch = useDispatch<any>();
  const { type = "all" } = props;
  const location = useLocation();

  const selectAllMentors = createSelector(
    (state: any) => state.Mentor,
    (mentor) => ({
      allMentorList: mentor.allMentorList,
      departments: mentor.departments,
      designations: mentor.designations,
      personalExperiences: mentor.personalExperiences,
      // AssignedMentorsList: mentor.AssignedMentorsList
      loading: false
    })
  );

  const selectPublicConfigProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      publicConfig: global.publicConfig,
    })
  );

  const { publicConfig } = useSelector(selectPublicConfigProperties);

  useEffect(() => {
    if (isEmpty(publicConfig)) {
      dispatch(getPublicConfig());
    }
  }, [dispatch, publicConfig]);

  const { allMentorList, loading, departments, designations, personalExperiences } = useSelector(selectAllMentors);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [isLoading, setLoading] = useState<boolean>(loading);
  const [filteredMentorList, setFilteredMentorList] = useState<FilteredMentorList | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);
  const [selectedDesignation, setSelectedDesignation] = useState<any>(null);
  const [selectedPersonalExperience, setSelectedPersonalExperience] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10)
  const userType = location.pathname === "/suggested-mentors" ? "mentee" : "mentor";

  useEffect(() => {
    if (type !== "suggested") {
      dispatch(getAllMentors({ perPage, page: currentPage }));
      dispatch(fetchDepartmentsAndDesignations());
      dispatch(fetchPersonalExperiences());
    }
  }, [dispatch, type, currentPage, perPage]);

  useEffect(() => {
    if (type === "suggested") {
      dispatch(fetchSuggestedMentors({ paginationData: { perPage, page: currentPage }, userType }))
        .then((response) => {
          console.log("Fetched Suggested Mentors Response:", response.payload);
          setFilteredMentorList(response.payload);
        });
    } else {
      setFilteredMentorList(allMentorList);
    }
  }, [allMentorList, type, perPage, currentPage, dispatch, userType]);
  


  const fetchMatchedMentors = () => {
    dispatch(getMatchedMentors({ page: currentPage, perPage }));
  }

  const handleSearchMentor = (value: any) => {
    const search = value.toLowerCase();
    setSearchTerm(search);
    if (search === "") {
      const filterTypes: any[] = [];
      const filterValues: any[] = [];
      if (selectedDepartment) {
        filterTypes.push("department");
        filterValues.push(selectedDepartment.value);
      }
      if (selectedDesignation) {
        filterTypes.push("designation");
        filterValues.push(selectedDesignation.value);
      }
      if (selectedPersonalExperience.length > 0) {
        filterTypes.push("surveyPersonalExperience");
        filterValues.push(selectedPersonalExperience.map((option: any) => option.value));
      }
      dispatch(
        applyMultipleFilters(
          { filterTypes, filterValues },
          { page: currentPage, perPage }
        )
      );

    } else {
      const filterTypes = ["name"];
      const filterValues = [search];
      if (selectedDepartment) {
        filterTypes.push("department");
        filterValues.push(selectedDepartment.value);
      }
      if (selectedDesignation) {
        filterTypes.push("designation");
        filterValues.push(selectedDesignation.value);
      }
      if (selectedPersonalExperience.length > 0) {
        filterTypes.push("surveyPersonalExperience");
        filterValues.push(selectedPersonalExperience.map((option: any) => option.value));
      }
      dispatch(
        applyMultipleFilters(
          { filterTypes, filterValues },
          { page: currentPage, perPage }
        )
      );
    }
  }

  const handleDepartmentChange = (selectedOption) => {
    const filterTypes: any[] = [];
    const filterValues: any[] = [];
    if (selectedOption === null) {
      setSelectedDepartment(null);
    } else {
      filterTypes.push("department");
      filterValues.push(selectedOption.value);
      setSelectedDepartment(selectedOption);
    }
    if (searchTerm !== "") {
      filterTypes.push("name");
      filterValues.push(searchTerm);
    }
    if (selectedDesignation) {
      filterTypes.push("designation");
      filterValues.push(selectedDesignation.value);
    }
    if (selectedPersonalExperience.length > 0) {
      filterTypes.push("surveyPersonalExperience");
      filterValues.push(selectedPersonalExperience.map((option: any) => option.value));
    }
    dispatch(
      applyMultipleFilters(
        { filterTypes, filterValues },
        { page: currentPage, perPage }
      )
    );
  }

  const handleDesignationChange = (selectedOption) => {
    const filterTypes: any[] = [];
    const filterValues: any[] = [];
    if (selectedOption === null) {
      setSelectedDesignation(null);
    } else {
      filterTypes.push("designation");
      filterValues.push(selectedOption.value);
      setSelectedDesignation(selectedOption);
    }
    if (searchTerm !== "") {
      filterTypes.push("name");
      filterValues.push(searchTerm);
    }
    if (selectedDepartment) {
      filterTypes.push("department");
      filterValues.push(selectedDepartment.value);
    }
    if (selectedPersonalExperience.length > 0) {
      filterTypes.push("surveyPersonalExperience");
      filterValues.push(selectedPersonalExperience.map((option: any) => option.value));
    }
    dispatch(
      applyMultipleFilters(
        { filterTypes, filterValues },
        { page: currentPage, perPage }
      )
    );
  }

  
  const handlePersonalExperienceChange = (selectedOption: any) => {
    const filterTypes: any[] = [];
    const filterValues: any[] = [];
    if (selectedOption === null) {
      setSelectedPersonalExperience([]);
    } else {
      filterTypes.push("surveyPersonalExperience");
      filterValues.push(selectedOption.map((option: any) => option.value));
      setSelectedPersonalExperience(selectedOption);
    }
    if (searchTerm !== "") {
      filterTypes.push("name");
      filterValues.push(searchTerm);
    }
    if (selectedDepartment) {
      filterTypes.push("department");
      filterValues.push(selectedDepartment.value);
    }
    if (selectedDesignation) {
      filterTypes.push("designation");
      filterValues.push(selectedDesignation.value);
    }
    dispatch(
      applyMultipleFilters(
        { filterTypes, filterValues },
        { page: currentPage, perPage }
      )
    );
  }

  const getListData = () => {
    if (type === "suggested") {
      return userType === "mentee"
        ? filteredMentorList?.mentorSuggestions || []
        : filteredMentorList?.menteesSuggestions || [];
    } else {
      return filteredMentorList?.mentors || [];
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {type !== "suggested" && (
            <Card className="job-filter">
              <CardBody>
                <Form action="#">
                  <div className="full-width-container">
                    <div className="row">
                      <div className="col-lg-9 col-12">
                        <div className="row">
                          <div className="col-12 col-md-6 mb-3">
                            <div className="position-relative">
                              <Input
                                type="text"
                                id="search"
                                autoComplete="off"
                                placeholder="Search by Name"
                                value={searchTerm}
                                onChange={(e) => handleSearchMentor(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 mb-3">
                            <Select
                              name="Personal Experience"
                              placeholder="Personal Experiences"
                              isClearable
                              isMulti
                              options={personalExperiences}
                              onChange={handlePersonalExperienceChange}
                              value={selectedPersonalExperience}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                menu: (base) => ({ ...base, maxHeight: 300 }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#fff"
                                    : provided.backgroundColor,
                                  color: state.isSelected ? "#000" : provided.color,
                                }),
                              }}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="d-block d-md-none mb-3">
                              <Select
                                name="designation"
                                placeholder="Designation"
                                isClearable
                                options={designations}
                                onChange={handleDesignationChange}
                                value={selectedDesignation}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  menu: (base) => ({ ...base, maxHeight: 300 }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                      ? "#fff"
                                      : provided.backgroundColor,
                                    color: state.isSelected ? "#000" : provided.color,
                                  }),
                                }}
                              />
                            </div>
                            <div className="d-none d-md-block mb-1">
                              <Select
                                name="designation"
                                placeholder="Designation"
                                isClearable
                                options={designations}
                                onChange={handleDesignationChange}
                                value={selectedDesignation}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  menu: (base) => ({ ...base, maxHeight: 300 }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                      ? "#fff"
                                      : provided.backgroundColor,
                                    color: state.isSelected ? "#000" : provided.color,
                                  }),
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 mb-1">
                            <Select
                              name="department"
                              placeholder="Department"
                              isClearable
                              options={departments}
                              onChange={handleDepartmentChange}
                              value={selectedDepartment}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                menu: (base) => ({ ...base, maxHeight: 300 }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#fff"
                                    : provided.backgroundColor,
                                  color: state.isSelected ? "#000" : provided.color,
                                }),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-12 d-flex justify-content-center align-items-center">
                        <div className="d-none d-lg-flex flex-column align-items-center me-3">
                          <div className="line-separator separator m-0"></div>
                          <div>OR</div>
                          <div className="line-separator separator m-0"></div>
                        </div>
                        <div className="w-100">
                          <div className="py-1 d-block d-lg-none">
                            <div className="separator">OR</div>
                          </div>
                          <Button
                            type="button"
                            color="primary"
                            className="w-100"
                            onClick={fetchMatchedMentors}
                          >
                            Match Me!
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          )}
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <>
              <List listData={getListData()} />
              <Paginations
                perPageData={perPage}
                setPerPage={setPerPage}
                totalDataCount={filteredMentorList?.total}
                totalPages={filteredMentorList?.totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isShowingPageLength={true}
                paginationClass="pagination pagination-rounded"
              />
            </>
          )}
        </Container>
      </div>
    </>
  );
}

export default MentorList;

