import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  userSelection: [],
  userConfig: {},
  myAvailability: {},
  calendarStatus: {},
  departments: [],
  genders: [],
  designations: [],
  customizedProfileFields: {},
};

const onboardingSlice = createSlice({
  name: "Onboarding",
  initialState,
  reducers: {
    setUserSelection(state, action) {
      state.userSelection = action.payload;
    },
    setMyAvailability(state, action) { 
      state.myAvailability = action.payload;
    },
    setCalendarStatus(state, action) {
      state.calendarStatus = action.payload;
    },
    removeCalendarStatus(state) {
      state.calendarStatus = {};
    },
    setUserDropDownData(state, action) {
      state.departments = action.payload.departments;
      state.genders = action.payload.genders;
      state.designations = action.payload.designations;
      state.customizedProfileFields = action.payload.CUSTOMIZED_PROFILE_FIELDS;
    },
  },
}
);

export const {
  setUserSelection,
  setMyAvailability,
  setCalendarStatus,
  removeCalendarStatus,
  setUserDropDownData
} = onboardingSlice.actions;
export default onboardingSlice.reducer;
