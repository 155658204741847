import { createSlice } from "@reduxjs/toolkit";
import { fetchMenteesInit, fetchMentorsInit, fetchMatchedMentorsWithMentee,  menteeSearchChange, mentorSearchChange} from './thunk';

export interface Person {
    label: string;
    value: number;
    id: string;
    avatarUrl: string;
    firstName: string;
    lastName: string;
    department: string;
  }
  
interface SuggestState {
    mentees: Person[];
    mentors: Person[];
    loading: boolean;
    error: string | null;
    total: number;
    perPage: number;
    page: number;
    totalPages: number;
    searchMenteesResults: Person[];  
    searchMentorsResults: Person[];  
}

const initialState: SuggestState = {
    mentees: [],
    mentors: [],
    loading: false,
    error: null,
    total: 0,
    perPage: 10,
    page: 1,
    totalPages: 0,
    searchMenteesResults: [], 
    searchMentorsResults: [],  
};

const suggestSlice = createSlice({
    name: 'Suggest',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenteesInit.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMenteesInit.fulfilled, (state, action) => {
                state.loading = false;
                state.mentees = action.payload;
            })
            .addCase(fetchMenteesInit.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch mentees';
            })

            .addCase(fetchMentorsInit.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMentorsInit.fulfilled, (state, action) => {
                state.loading = false;
                state.mentors = action.payload;
            })
            .addCase(fetchMentorsInit.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch mentors';
            })

            .addCase(fetchMatchedMentorsWithMentee.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMatchedMentorsWithMentee.fulfilled, (state, action) => {
                state.loading = false;
                state.mentors = action.payload;
            })
            .addCase(fetchMatchedMentorsWithMentee.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch matched mentors';
            })

            .addCase(menteeSearchChange.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.searchMenteesResults = [];
              })
              .addCase(menteeSearchChange.fulfilled, (state, action) => {
                state.loading = false;
                state.searchMenteesResults = action.payload; 
              })
              .addCase(menteeSearchChange.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to search mentees';
              })
        
              .addCase(mentorSearchChange.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.searchMentorsResults = [];
              })
              .addCase(mentorSearchChange.fulfilled, (state, action) => {
                state.loading = false;
                state.searchMentorsResults = action.payload; 
              })
              .addCase(mentorSearchChange.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to search mentors';
              });
    },
});

export const SuggestReducer = suggestSlice.reducer;




