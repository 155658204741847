import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import { ONBOARDING, USER_PROFILE_STATUS, USER_SELECTIONS } from "Components/constants/userData";
import { removeCalendarStatus, setCalendarStatus, setMyAvailability, setUserDropDownData, setUserSelection } from "./reducer";
import { setUserConfig } from "slices/thunk";

const api = new APIClient();

export const uploadProfilePicture = (values: any) => async (dispatch: any) => {
  try {
    const data = await api.uploadProfilePhoto(
      "/api/v1/onboarding/upload/profile-photo",
      values.file
    );
    
    if (data.status) {
      toast.success("File uploaded successfully", { autoClose: 2000 });

      const data = await api.create(`/api/v1/user-config/${ONBOARDING}`, {
        mode: 'UPDATE',
        value: {
          [USER_PROFILE_STATUS.profile_picture]: true
        },
      });

      if (data) {
        dispatch(setUserConfig({ config: data.value, key: `${ONBOARDING}` }));
      }
    }

  } catch (forgetError) {
    console.log('forget error is ', forgetError);
  }
}

export const fetchOrgMetaData = () => async (dispatch: any) => {
  try {
    const data = await api.get('/api/v1/org-configs', null);
    dispatch(setUserDropDownData(data));
  } catch (error) {
    return error;
  }
}

export const postUserFormData = (data: any, additionalProfileValues: any, navigate: any) => async (dispatch: any) => {
  try {
    const {
      firstName,
      lastName,
      gender,
      bio,
      department,
      title: position,
      yearsOfExperience,
      location,
      country,
      language,
      linkedInProfile,
      onProfilePage,
      timezone,
      additionalProfileValues,
    } = data;

    const response = await api.create('/api/v1/onboarding/profile', {
      firstName: firstName,
      lastName: lastName,
      gender,
      department,
      designation: position,
      title: position,
      yearsOfExperience,
      location,
      country,
      language,
      linkedInProfile,
      bio,
      timezone,
      additionalProfileValues,
    });

    if (response) {
      toast.success("Form saved successfully", { autoClose: 2000 });
      const data = await api.create(`/api/v1/user-config/${ONBOARDING}`, {
        mode: 'UPDATE',
        value: {
          [USER_PROFILE_STATUS.profile_form]: true
        },
      });
      if (data) {
        dispatch(setUserConfig({ config: data.value, key: `${ONBOARDING}` }));
      }
      if (onProfilePage) {
        window.location.reload();
      } else {
        navigate("/onboarding/create");
      }
    } else {
      toast.error("Request Failed", { autoClose: 2000 });
    }
  } catch (error) {
    console.error(error);
    toast.error("An error occurred", { autoClose: 2000 });
  }
}

export const postFormData =
  (data: any, formId: string, formType: string, profilePage?: boolean) =>
  async (dispatch: any) => {
    try {
      const response = await api.create(
        `/api/v1/onboarding/profile/${formType}`,
        {
          formResponse: data,
        }
      );

      if (response) {
        toast.success("Form saved successfully.");
        if (formType === "mentor" && profilePage)
          toast.info(
            "Please submit the availability and connect to the calendar if not done previously."
          );
      } else {
        toast.error("Request Failed", { autoClose: 2000 });
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred", { autoClose: 2000 });
    }
  };

export const getUserConfig = (configData: any) => async (dispatch: any) => {
  try {
    const data = await api.get(`/api/v1/user-config/${configData.key}`, null);
    if (data) {
      dispatch(
        setUserConfig({ config: data.userConfig.value, key: configData.key })
      );
    }
  } catch (error) {
    return error;
  }
}

export const userTypeSelections = (userSelection: any, onboardingConfig: any) => async (dispatch: any) => {
  try {
    const allData = { ...onboardingConfig };
    dispatch(setUserSelection(userSelection));

    const newData = userSelection.reduce((acc: any, key: any) => {
      if (key === 'mentor' || key === 'mentee') {
        acc[key] = true;
      }
      return acc;
    }, {});

    api.create(`/api/v1/user-config/${ONBOARDING}`, {
      value: { ...allData, [USER_SELECTIONS]: newData },
      mode: 'REPLACE',
    }).then(response => {
      dispatch(setUserConfig({ config: response?.value || {}, key: 'onboarding' }));
    }).catch((error) => {
      console.error(error);
    });
  } catch (error) {
    return error;
  }
}

export const postOnboardingCompleted = () => async (dispatch: any) => {
  try {
    const data = await api.create(`/api/v1/user-config/${ONBOARDING}`, {
      value: { [USER_PROFILE_STATUS.onboarding_completed]: true },
      mode: 'UPDATE',
    });
    if (data) {
      dispatch(setUserConfig({ config: data.value, key: `${ONBOARDING}` }));
    }
  } catch (error) {
    return error;
  }
}

export const getMyAvailability = () => async (dispatch: any) => {
  try {
    const data = await api.get('/api/v1/my/availability-slots', null);
    dispatch(setMyAvailability(data));    
  } catch (error) {
    return error;
  }
}

export const getCalendarStatus = () => async (dispatch: any) => {
  try {
    const data = await api.get('/api/v1/onboarding/calendar/status', null);
    dispatch(setCalendarStatus(data));
  } catch (error) {
    console.log('Error is ', error);
    return error;
  }
}

export const disconnectCalendar = (provider: string) => async (dispatch: any) => {
  try {
    const data = await api.create(`/api/v1/onboarding/calendar/disconnect/${provider}`, null);
    if (data.status) {
      dispatch(removeCalendarStatus(data));
    }
  } catch (error) {
    return error;
  }
}
