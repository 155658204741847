import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, CardBody, CardTitle, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { getSlots } from "slices/mentor/thunk";
import { format, parse, parseISO } from "date-fns";
import { requestSession } from "slices/session/thunk";
import { APIClient } from "../helpers/api_helper";
import AvailabilityCalendar from "./AvailabilityCalendar";
import RescheduleCalendar from "./RescheduleCalendar";
import Select from 'react-select';
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";

const api = new APIClient();

const AvailableSlots = (props: any) => {
  const { mentorId, reschedule=false, requestId, rescheduleType, isMentor, toggleRescheduleModal=null } = props;
  const dispatch = useDispatch<any>();
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [loading, setLoading] = useState(true);
  const [availableSlots, setAvailableSlots] = useState<any>({});
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<any>({});
  const [availableTime, setAvailableTime] = useState<any>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [availability, setAvailability] = useState<boolean>(false);
  const [selectedFeatures, setSelectedFeatures] = useState<any>([]);
  const [notes, setNotes] = useState<string>('');
  const [skills, setSkills] = useState<any>([]);
  const [mySkills, setMySkills] = useState<any>([]);
  const [booking, setBooking] = useState<boolean>(false);
  
  const selectProfileProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      user: global.user,
    })
  );

  const { user } = useSelector(selectProfileProperties);
  const menteeFields = user.menteeFields;

  const toggleCalendar = useCallback(() => {
    setAvailability(!availability);
  }, [availability])

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const fetchSlots = useCallback(() => {
    if (isMentor) {
      setLoading(false);
      return
    }
    const today = format(new Date(), 'yyyy-MM-dd');
    const nextWeek = new Date();
    const nextWeekDay = format(nextWeek.setDate(nextWeek.getDate() + 6), 'yyyy-MM-dd');
    dispatch(getSlots({ from: today, to: nextWeekDay, userId: mentorId }))
      .then((fetchedSlots: any) => {
        setAvailableSlots(fetchedSlots['availableSlots']);
        const firstDay = fetchedSlots['availableSlots'][0];
        setSelectedDay(firstDay.date);
        setSelectedTime(firstDay.slots[0]);
        setAvailableTime(firstDay.slots);
        setLoading(false);
      }).catch((error: any) => {
        setAvailableSlots([]);
        console.error(error);
        setLoading(false);
      });
  }, [dispatch, mentorId, isMentor]);

  useEffect(() => {
    fetchSlots();
  }, [fetchSlots]);

  const fetchSkills = async () => {
    await api.get('/api/v1/sessions/skills').then((response: any) => {
      const options = response.map((group: any) => ({
        label: group.title,
        name: group.name,
        options: group.choices.map((choice: any) => ({
            label: choice.text,
            value: choice.value
        }))
      }));
      setSkills(options);
    }).catch((error: any) => {
      console.log(error);
      toast.error("Error while fetching skills.")
    });
  }

  const filterSkills = useCallback(() => {
    if (!isEmpty(menteeFields) && skills.length > 0) {
      const arr = skills.map(skill => {
        if (menteeFields[skill.name]) {
            const filteredChoices = skill.options.filter(choice => menteeFields[skill.name].includes(choice.value));
            if (filteredChoices.length > 0) {
                return { label: skill.label, name: skill.name, options: filteredChoices };
            }
        }
        return null;
      }).filter(skill => skill !== null);
      setMySkills(arr);
    }
  }, [menteeFields, skills]);

  useEffect(() => {
    fetchSkills();
  }, [])

  useEffect(() => {
    filterSkills();
  }, [filterSkills])

  const [mode] = useState<string>('REMOTE');

  const onChange = (selectedOption) => {
    setSelectedFeatures(selectedOption);
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (booking) return;
    setBooking(true); 

    try {
        await dispatch(requestSession({
            mentorId,
            date: selectedDay,
            time: selectedTime.start,
            skills: selectedFeatures.map((feature: any) => feature.value),
            notes,
            mode,
        }));
        
        fetchSlots();
        toggle();
        setSelectedFeatures([]);
        setNotes('');
        setSelectedFeatures([]);
    } catch (error) {
        console.error(error);
    } finally {
        setBooking(false); 
    }
  };

  const openModal = (e: any) => {
    e.preventDefault();
    toggle();
  }

  const getDay = (date: any) => { 
    const day = parseISO(date).getDay();
    return days[day];
  }

  const convertTo12HourFormat = (time: string) => {
    const parsedTime = parse(time, 'HH:mm', new Date());
    return format(parsedTime, 'hh:mm a');
  };

  const setDayAndSlots = (value: any) => {
    setSelectedDay(value);
    const selected = availableSlots.find((slot: any) => slot.date === value);
    setAvailableTime(selected.slots);
    setSelectedTime(selected.slots[0]);
  }

  if (!availableSlots) {
    return <CardBody>Loading...</CardBody>;
  }

  const openCalendar = () => {
    toggleCalendar();
  }

  return (
    <>
      {reschedule ? (
        <RescheduleCalendar
          mentorId={mentorId}
          fetchSlots={fetchSlots}
          requestId={requestId}
          rescheduleType={rescheduleType}
          isMentor={isMentor}
          toggle={toggleCalendar}
          toggleRescheduleModal={toggleRescheduleModal}
        />
      ) : (
        <>
          <AvailabilityCalendar
            isOpen={availability}
            toggle={toggleCalendar}
            mentorId={mentorId}
            fetchSlots={fetchSlots}
          />
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Book Session
            </ModalHeader>
            <ModalBody>
              <Row className="mb-3 justify-content-center">
                <Col md={11}>
                  <p className="mb-2">Goals/Skills I want mentoring on</p>
                  <Select
                    classNamePrefix="select2-selection modal-dialog-scrollable modal-dialog"
                    name="features"
                    placeholder="Choose..."
                    options={mySkills}
                    isMulti
                    onChange={onChange}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: 'white',
                        cursor: 'pointer',
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        backgroundColor: 'white',
                      }),
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                      placeholder: styles => ({ ...styles, marginBottom: '16px' }),
                    }}
                    menuPortalTarget={document.body}
                  />
                </Col>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Col md={11}>
                  <p className="mb-2">Notes</p>
                  <Input
                    type="textarea"
                    name="notes"
                    placeholder="Notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Col md={11}>
                  <p className="mb-2">Mode</p>
                  <div className="d-flex flex-wrap">
                    <Label check className="me-3">
                      Virtual
                    </Label>
                  </div>
                </Col>
              </Row>

              <div className="text-right d-flex justify-content-end mt-2">
                <Button
                  className="btn btn-primary me-3"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={booking || !selectedDay || !selectedTime || selectedFeatures.length === 0 || notes === '' }
                >
                  Book
                </Button>
                <Button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                >
                  Close
                </Button>
              </div>
            </ModalBody>
          </Modal>
          <CardBody>
            <CardTitle style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Available Sessions</span>
              <span className="mb-2 font-size-12 fw-light">Book 1:1 sessions from the options based on your needs</span>
            </CardTitle>
            <Form onSubmit={openModal}>
              <div className="mb-2">
                <Row>
                  {availableSlots.length ? availableSlots.map((day: any, index: any) => (
                    <Col key={index} xl={2} sm={2} style={{ width: '6rem' }}>
                      <label className="card-radio-label mb-0">
                        <input
                          type="radio"
                          name="day"
                          className="card-radio-input"
                          value={day.date}
                          checked={day.date === selectedDay}
                          onChange={(e) => setDayAndSlots(e.target.value)}
                        />
                        <div className="card-radio" style={{ padding: '10px 5px' }}>
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <span className="text-uppercase font-size-10">{getDay(day.date)}</span>
                            <span className="fw-bold">{format(parseISO(day.date), 'd MMM')}</span>
                            <span className="font-size-10 mt-2 text-success">{day.slots.length || 0} slots</span>
                          </div>
                        </div>
                      </label>
                    </Col>
                  )) : <Label>No slots available</Label>}
                  {availableSlots.length ? (
                    <Col className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                      <span onClick={openCalendar}>View All</span>
                    </Col>
                  ) : null
                  }
                </Row>
                <Row className="mt-5">
                {availableSlots.length > 0 && (
                  <>
                  <Label style={{ margin: '0px' }}>Available time slots</Label>
                  <div className="mb-1">
                    <hr style={{ border: '0.1px solid #e3e3e3', margin: '5px 0px' }} />
                  </div>
                  {availableTime.length > 0 ? availableTime.map((slotData: any, index: any) => (
                    <Col key={index} xl={2} sm={2}>
                      <div className="ms-3">
                        <label className="card-radio-label mb-2">
                          <input
                            type="radio"
                            name="time"
                            className="card-radio-input"
                            value={slotData.start}
                            checked={slotData.start === selectedTime.start}
                            onChange={() => setSelectedTime(slotData)}
                          />
                          <div className="card-radio">
                            <div className="d-flex justify-content-center align-items-center">
                              <span>{convertTo12HourFormat(slotData.start)}</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </Col>
                  )) : <Label>No slots available</Label>}
                  </>
                )}
                </Row>
              </div>
              <div className="text-center mt-4">
                <Button color="primary" disabled={!selectedDay || !selectedTime}>Book Session</Button>
              </div>
            </Form>
          </CardBody>
        </>
      )}
    </>
  );
}

export default AvailableSlots;
