import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { APIClient } from "../../helpers/api_helper";
import UserList from "../../Components/UserList";
import user1 from "assets/images/users/user_profile.svg";
import { toast } from "react-toastify";
import "../../assets/scss/custom/components/UserList.scss";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useAppDispatch } from "slices";
import { getPublicConfig } from "slices/thunk";
import { isEmpty } from "lodash";
import { fetchMenteesInit, fetchMentorsInit, fetchMatchedMentorsWithMentee, menteeSearchChange, mentorSearchChange } from 'slices/suggest/thunk';
import Paginations from "Components/Common/Pagination";

interface Person {
  label: string;
  value: number;
  id: string;
  avatarUrl: string;
  firstName: string;
  lastName: string;
  department: string;
}

const api = new APIClient();

export const Suggest = () => {

  const [activeTab, setactiveTab] = useState<number>(1);
  const [menteesearchQuery, setMenteeSearchQuery] = useState('');
  const [mentorSearchQuery, setMentorSearchQuery] = useState('');
  const [menteePage, setMenteePage] = useState<number>(1);
  const [searchMenteePage, setSearchMenteePage] = useState<number>(1);
  const [menteePerPage, setMenteePerPage] = useState<number>(10);
  const [searchMenteePerPage, setSearchMenteePerPage] = useState<number>(10);
  const [mentorPage, setMentorPage] = useState<number>(1);
  const [searchMentorPage, setSearchMentorPage] = useState<number>(1);
  const [mentorPerPage, setMentorPerPage] = useState<number>(10);
  const [searchMentorPerPage, setSearchMentorPerPage] = useState<number>(10);
  const [selectedMentee, setSelectedMentee] = useState<any | null>(null);
  const [selectedMentor, setSelectedMentor] = useState<any | null>(null);
  const [passedSteps, setPassedSteps] = useState<number[]>([1]);

  const dispatch = useAppDispatch();

  const selectPublicConfigProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      publicConfig: global.publicConfig,
    })
  );

  type SuggestState = any;
  const { mentees, mentors, searchMenteesResults, searchMentorsResults } = useSelector((state: { Suggest: SuggestState }) => state.Suggest);
  const { publicConfig } = useSelector(selectPublicConfigProperties);

  useEffect(() => {
    if (isEmpty(publicConfig)) {
      dispatch(getPublicConfig());
    }
  }, [dispatch, publicConfig]);


  useEffect(() => {
    if (menteesearchQuery.trim()) {
      dispatch(
        menteeSearchChange({
          searchQuery: menteesearchQuery,
          paginationData: { perPage: searchMenteePerPage, page: searchMenteePage },
        })
      );
    } else {
      dispatch(fetchMenteesInit({ perPage: menteePerPage, page: menteePage }));
    }
  }, [dispatch, menteePage, menteePerPage, searchMenteePerPage, searchMenteePage, menteesearchQuery]);

  
  function toggleTab(tab: number) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }


  const handleMenteeSelect = (mentee: Person) => {
    setSelectedMentee(mentee);
  };
  useEffect(() => {
    if (
      selectedMentee &&
      publicConfig &&
      publicConfig.organizationSettings &&
      !publicConfig.organizationSettings.disableMatchMeForOrgAdmin
    ) {
      dispatch(
        fetchMatchedMentorsWithMentee({
          menteeId: selectedMentee.id,
          paginationData: { perPage: mentorPerPage, page: mentorPage },
        })
      );
    } else if (selectedMentee) {
      dispatch(fetchMentorsInit({ perPage: mentorPerPage, page: mentorPage }));
    }
  }, [selectedMentee, publicConfig, mentorPage, mentorPerPage, dispatch]);

  useEffect(() => {
    if (selectedMentee && mentorSearchQuery.trim()) {
      dispatch(
        mentorSearchChange({
          searchQuery: mentorSearchQuery,
          paginationData: { perPage: searchMentorPerPage, page: searchMentorPage },
        })
      );
    }
  }, [searchMentorPage, searchMentorPerPage, mentorSearchQuery, selectedMentee, dispatch]);


  const handleMentorSelect = (mentor: Person) => {
    setSelectedMentor(mentor);
  };

  const handleConfirm = async () => {
    if (selectedMentor && selectedMentee) {
      api.create(`api/v1/users/${selectedMentee.id}/suggest-mentor`, {
        mentorId: selectedMentor.id
      }).then(() => {
        toast.success('Mentor-Mentee pairing was successful');
        window.location.reload();
      }).catch((error) => {
        toast.error(error.message || `Mentor suggestion failed`);
      })
    }
  };

  const handleMenteeSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;
    setMenteeSearchQuery(searchQuery);
    if (searchQuery.trim()) {
      dispatch(
        menteeSearchChange({
          searchQuery,
          paginationData: { perPage: searchMenteePerPage, page: searchMenteePage },
        })
      );
    } else {
      dispatch(fetchMenteesInit({ perPage: menteePerPage, page: menteePage }));
    }
  };

  

  const handleMentorSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value;
    setMentorSearchQuery(searchQuery);
    if (searchQuery.trim()) {
      dispatch(
        mentorSearchChange({
          searchQuery,
          paginationData: { perPage: searchMentorPerPage, page: searchMentorPage },
        })
      );
    } else {
      dispatch(fetchMentorsInit({ perPage: mentorPerPage, page: mentorPage }));
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Pairing" breadcrumbItem="Pair Mentor-Mentee" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem className={classnames({ current: activeTab === 1 })}>
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => setactiveTab(1)}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Select Mentee
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTab === 2 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => setactiveTab(2)}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Select Mentor
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTab === 3 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => setactiveTab(3)}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span> Confirm Matching
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <div className="mb-3">

                            <input
                              type="text"
                              placeholder="Search mentees..."
                              value={menteesearchQuery}
                              onChange={handleMenteeSearchChange}
                              className="form-control user-list search-input"
                            />
                          </div>

                          <UserList
                            users={
                              menteesearchQuery.trim()
                                ? searchMenteesResults.mentors
                                : mentees.mentors
                            }
                            onSelect={handleMenteeSelect}
                            selectedUser={selectedMentee}
                            removeUser={selectedMentor}
                            userType="mentee"

                          />

                        </TabPane>
                        <TabPane tabId={2}>
                          <div className="mb-3">
                            <input
                              type="text"
                              placeholder="Search mentors..."
                              value={mentorSearchQuery}
                              onChange={handleMentorSearchChange}
                              className="form-control user-list search-input"
                            />
                          </div>
                          <UserList
                            users={
                              mentorSearchQuery.trim()
                                ? searchMentorsResults.mentors
                                : mentors.mentors
                            }
                            onSelect={handleMentorSelect}
                            selectedUser={selectedMentor}
                            removeUser={selectedMentee}
                            userType="mentor"
                          />


                        </TabPane>
                        <TabPane tabId={3}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Confirm Detail</h5>
                                  <p className="text-muted">Confirm the mentor-mentee matching</p>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <img
                                      src={selectedMentee?.avatarUrl || user1}
                                      alt={selectedMentee?.id}
                                      className="rounded-circle"
                                      style={{ width: '40px', height: '40px' }}
                                    />
                                    <span className="ms-2">{selectedMentee?.firstName}</span>
                                    <span className="ms-2 me-3">🔗</span>
                                    <img
                                      src={selectedMentor?.avatarUrl || user1}
                                      alt={selectedMentor?.id}
                                      className="rounded-circle"
                                      style={{ width: '40px', height: '40px' }}
                                    />
                                    <span className="ms-2">{selectedMentor?.firstName}</span>
                                  </div>
                                  <button className="btn btn-primary mt-3" onClick={handleConfirm}>
                                    Confirm
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li className={activeTab === 1 ? "previous disabled" : "previous"}>
                          <Link to="#" onClick={() => {
                            if (activeTab !== 1) {
                              toggleTab(activeTab - 1);
                            }
                          }}>
                            Previous
                          </Link>
                        </li>

                        <li className={(activeTab === 1 && !selectedMentee) || (activeTab === 2 && !selectedMentor) ? "next disabled" : "next"}>
                          <Link to="#" onClick={() => {
                            if (!((activeTab === 1 && !selectedMentee) || (activeTab === 2 && !selectedMentor))) {
                              toggleTab(activeTab + 1);
                            }
                          }}>
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <Paginations
                    perPageData={
                      activeTab === 1
                        ? (menteesearchQuery.trim() ? searchMenteePerPage : menteePerPage)
                        : (mentorSearchQuery.trim() ? searchMentorPerPage : mentorPerPage)
                    }
                    setPerPage={
                      activeTab === 1
                        ? (menteesearchQuery.trim() ? setSearchMenteePerPage : setMenteePerPage)
                        : (mentorSearchQuery.trim() ? setSearchMentorPerPage : setMentorPerPage)
                    }
                    totalDataCount={
                      activeTab === 1
                        ? (menteesearchQuery.trim() ? searchMenteesResults.total : mentees.total)
                        : (mentorSearchQuery.trim() ? searchMentorsResults.total : mentors.total)
                    }
                    totalPages={
                      activeTab === 1
                        ? (menteesearchQuery.trim() ? searchMenteesResults.totalPages : mentees.totalPages)
                        : (mentorSearchQuery.trim() ? searchMentorsResults.totalPages : mentors.totalPages)
                    }
                    currentPage={
                      activeTab === 1
                        ? (menteesearchQuery.trim() ? searchMenteePage : menteePage)
                        : (mentorSearchQuery.trim() ? searchMentorPage : mentorPage)
                    }
                    setCurrentPage={
                      activeTab === 1
                        ? (menteesearchQuery.trim() ? setSearchMenteePage : setMenteePage)
                        : (mentorSearchQuery.trim() ? setSearchMentorPage : setMentorPage)
                    }
                    isShowingPageLength={true}
                    paginationClass="pagination pagination-rounded"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};