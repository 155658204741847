import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const api = new APIClient();

export const fetchMenteesInit = createAsyncThunk(
  "suggest/fetchMenteesInit",
  async (paginationData: { page: number; perPage: number }) => {
    try {
      const { page, perPage } = paginationData;
      const response = await api.get(
        `api/v1/search?page=${page}&perPage=${perPage}&filter.profileType=Mentee`
      );
      return response;
    } catch (error) {
      console.error("Error fetching mentees", error);
      toast.error("Failed to fetch mentee data");
      throw error;
    }
  }
);

  
export const fetchMentorsInit = createAsyncThunk(
    "suggest/fetchMentorsInit",
    async (paginationData: { page: number; perPage: number }) => {
        try {
          const { page, perPage } = paginationData;
          const response = await api.get(
            `api/v1/search?page=${page}&perPage=${perPage}&filter.profileType=Mentor`
          );
            return response;
        } catch (error) {
            console.error("Error fetching mentors", error);
            // toast.error("Failed to fetch mentors data");
            throw error;
        }
    }
);

export const fetchMatchedMentorsWithMentee = createAsyncThunk(
  "suggest/fetchMatchedMentorsWithMentee",
  async (
    payload: { menteeId?: string; paginationData: { page: number; perPage: number } }
  ) => {
    try {
      const { menteeId, paginationData } = payload;
      const { page, perPage } = paginationData;
      const response = await api.get(
        `api/v1/search/matched/${menteeId}?filter.profileType=Mentor&page=${page}&perPage=${perPage}`
      );

      return response;
    } catch (error) {
      console.error("Error fetching matched mentors", error);
      toast.error("Failed to fetch matched mentors data");
      throw error;
    }
  }
);


export const menteeSearchChange = createAsyncThunk(
  "suggest/menteeSearchChange",
  async (
    { searchQuery, paginationData }: { searchQuery: string; paginationData: { page: number; perPage: number } },
    { rejectWithValue }
  ) => {
    try {
      const { page, perPage } = paginationData;
      const response = await api.get(
        `api/v1/search?filter.name=${searchQuery}&filter.profileType=Mentee&page=${page}&perPage=${perPage}`
      );
      return response;
    } catch (error) {
      console.error("Error searching mentees", error);
      toast.error("Failed to fetch mentees");
      return rejectWithValue(error);
    }
  }
);

export const mentorSearchChange = createAsyncThunk(
  "suggest/mentorSearchChange",
  async (
    { searchQuery, paginationData }: { searchQuery: string; paginationData: { page: number; perPage: number } },
    { rejectWithValue }
  ) => {
    try {
      const { page, perPage } = paginationData;
      const response = await api.get(
        `api/v1/search?filter.name=${searchQuery}&filter.profileType=Mentor&page=${page}&perPage=${perPage}`
      );
      return response;
    } catch (error) {
      console.error("Error searching mentors", error);
      toast.error("Failed to fetch mentors");
      return rejectWithValue(error);
    }
  }
);

