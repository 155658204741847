import React, {useState, useEffect } from "react";
import {
  Col,
  Row,
  Label,
  Form,
  Input,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { fetchOrgMetaData, postUserFormData } from "slices/onboarding/thunk";
import { useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import Select from "react-select";
import countryList from "react-select-country-list";
import iso6391 from 'iso-639-1';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getUser } from 'slices/thunk';
import CustomProfileFields from "../../Components/CustomProfileFields";

dayjs.extend(utc);
dayjs.extend(timezone);

export const InfoForm = ({ onProfilePage = false }) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const selectProfileProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      userConfig: global.userConfig,
      user: global.user,
    })
  );
  const { user } = useSelector(selectProfileProperties);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const selectOrgMetaData = createSelector(
    (state: any) => state.Onboarding,
    (onboarding) => ({
      departments: onboarding.departments,
      genders: onboarding.genders,
      designations: onboarding.designations,
      customizedProfileFields: onboarding.customizedProfileFields,
    })
  );
  const { departments, genders, designations: titles, customizedProfileFields } = useSelector(selectOrgMetaData);

  const [additionalProfileValues, setAdditionalProfileValues] = useState(user.additionalProfileValues || {});
  const [additionalErrors, setAdditionalErrors] = useState<Record<string, string>>({});

  const { additionalFields = {}, customLabels = {} } = customizedProfileFields ?? {};

  const handleAdditionalProfileChange = (newValue, key) => {
    const updatedValues = { ...additionalProfileValues, [key]: newValue };
    setAdditionalProfileValues(updatedValues);
  };

  const validateAdditionalFields = (values: Record<string, any>) => {
    const errors: Record<string, string> = {};
    Object.keys(additionalFields).forEach((key) => {
      const field = additionalFields[key];
      if (field.required && (!values[key] || values[key] === "")) {
        errors[key] = `Please select ${field.label}`;
      }
    });
    return errors;
  };
  
  useEffect(() => {
    dispatch(fetchOrgMetaData());
  }, [dispatch]);

  const formik: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      gender: user.gender || "",
      department: user.department || "",
      title: user.title || "",
      yearsOfExperience: typeof user.yearsOfExperience === 'number' ? user.yearsOfExperience : "",
      location: user.location || "",
      country: user.country || "",
      language: user.language ? user.language.split(",") : [],
      bio: user.bio || "",
      linkedInProfile: user.linkedInProfile || "",
      timezone: user.timezone || dayjs.tz.guess(),
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("This field is required"),
      lastName: Yup.string().required("This field is required"),
      gender: Yup.string().required("This field is required"),
      department: Yup.string().required("This field is required"),
      title: Yup.string().required("This field is required"),
      yearsOfExperience: Yup.number()
        .required("Years of Experience is required")
        .min(0, "Minimum Years of Experience is 0")
        .max(70, "Maximum Years of Experience is 70"),

      location: Yup.string().required("This field is required"),
      country: Yup.string().required("This field is required"),
      language: Yup.array().required("At least one language is required"),
      bio: Yup.string().required("This field is required"),
      linkedInProfile: Yup.string().nullable().notRequired()
        .matches(
          /^https:\/\/www\.linkedin\.com\/.*$/,
          "Profile url is not valid"
        ),
      timezone: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      const validationErrors = validateAdditionalFields(additionalProfileValues);
      if (Object.keys(validationErrors).length > 0) {
        setAdditionalErrors(validationErrors);
        return;
      }
      const formData = {
        ...values,
        additionalProfileValues,
        language: values.language.join(","),
        onProfilePage: onProfilePage,
      };
      dispatch(postUserFormData(formData, additionalProfileValues, navigate))
    },
  });

  const TimeZoneOption = ({ gmtOffset, timezone, currentTime }) => {
    const optionStyle = {
      display: 'flex',
      justifyContent: 'space-between'
    };

    const currentTimeStyle = {
      marginLeft: 'auto'
    };
    return (
      <div style={optionStyle}>
        <span>{`(${gmtOffset}) ${timezone}`}</span>
        <span style={currentTimeStyle}>{currentTime}</span>
      </div>
    );
  };
  const timezones = Intl.supportedValuesOf("timeZone");
  const timezoneOptions = timezones.map((timezone) => {
    const currentTime = new Date().toLocaleTimeString("en-US", {
      timeZone: timezone,
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
    const offset = new Date().toLocaleTimeString("en-US", {
      timeZone: timezone,
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZoneName: "short"
    });
    const gmtOffset = offset.split(' ')[1];
    return {
      value: timezone,
      label: <TimeZoneOption gmtOffset={gmtOffset} timezone={timezone} currentTime={currentTime} />
    };
  });

  const countryOptions = countryList().getData().map((country: any) => ({
    value: country.value,
    label: `${country.label}`
  }));

  const languageOptions = iso6391.getAllNames().map((language) => ({
    value: language,
    label: language,
  }));

  return (
    <div className="container">
      <Form onSubmit={formik.handleSubmit} className="mt-5">
        <Row className="mb-3 justify-content-center">
          <Col md={4}>
            <Label htmlFor="formrow-firstName-Input">First Name</Label>
            <Input
              type="text"
              name="firstName"
              id="formrow-firstName-Input"
              placeholder="Enter First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.firstName && formik.errors.firstName}
            />
            {formik.errors.firstName && formik.touched.firstName && (
              <FormFeedback>{formik.errors.firstName}</FormFeedback>
            )}
          </Col>
          <Col md={4}>
            <Label htmlFor="formrow-lastName-Input">Last Name</Label>
            <Input
              type="text"
              name="lastName"
              id="formrow-lastName-Input"
              placeholder="Enter Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.lastName && formik.errors.lastName}
            />
            {formik.errors.lastName && formik.touched.lastName && (
              <FormFeedback>{formik.errors.lastName}</FormFeedback>
            )}
          </Col>
        </Row>
        <Row className="mb-3 justify-content-center">
          <Col md={4}>
            <Label htmlFor="formrow-gender-Input">Gender</Label>
            <Input
              type="select"
              name="gender"
              id="formrow-gender-Input"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.gender && formik.errors.gender}
            >
              <option value="">Select Gender</option>
              {genders.map((gender: any) => (
                <option key={gender.value} value={gender.value}>
                  {gender.title}
                </option>
              ))}
            </Input>
            {formik.errors.gender && formik.touched.gender && (
              <FormFeedback>{formik.errors.gender}</FormFeedback>
            )}
          </Col>
          <Col md={4}>
            <Label htmlFor="formrow-yearsOfExperience-Input">Total Years of Experience</Label>
            <Input
              type="number"
              name="yearsOfExperience"
              id="formrow-yearsOfExperience-Input"
              placeholder="Enter Years of Experience"
              value={formik.values.yearsOfExperience}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.yearsOfExperience && formik.errors.yearsOfExperience}
            />
            {formik.errors.yearsOfExperience && formik.touched.yearsOfExperience && (
              <FormFeedback>{formik.errors.yearsOfExperience}</FormFeedback>
            )}
          </Col>
        </Row>
        <Row className="mb-3 justify-content-center">
          <Col md={4}>
            <Label htmlFor="formrow-department-Input">{customLabels['department'] ?? 'Department'}</Label>
            <Input
              type="select"
              name="department"
              id="formrow-department-Input"
              value={formik.values.department}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.department && formik.errors.department}
            >
              <option value="">Select {customLabels['department'] ?? 'Department'}</option>
              {departments.map((department: any) => (
                <option key={department.value} value={department.value}>
                  {department.title}
                </option>
              ))}
            </Input>
            {formik.errors.department && formik.touched.department && (
              <FormFeedback>{formik.errors.department}</FormFeedback>
            )}
          </Col>
          <Col md={4}>
            <Label htmlFor="formrow-title-Input">{customLabels['title'] ?? 'Current Title'}</Label>
            <Input
              type="select"
              name="title"
              id="formrow-title-Input"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.title && formik.errors.title}
            >
              <option value="">Select {customLabels['title'] ?? 'Current Title'}</option>
              {titles.map((title: any) => (
                <option key={title.value} value={title.value}>
                  {title.title}
                </option>
              ))}
            </Input>
            {formik.errors.title && formik.touched.title && (
              <FormFeedback>{formik.errors.title}</FormFeedback>
            )}
          </Col>
        </Row>
        <Row className="mb-3 justify-content-center">
          <Col md={4}>
            <Label htmlFor="formrow-location-Input">Location</Label>
            <Input
              type="text"
              name="location"
              id="formrow-location-Input"
              placeholder="Enter Location"
              value={formik.values.location}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.location && formik.errors.location}
            />
            {formik.errors.location && formik.touched.location && (
              <FormFeedback>{formik.errors.location}</FormFeedback>
            )}
          </Col>
          <Col md={4}>
            <Label htmlFor="formrow-country-Input">Country</Label>
            <Select
              name="country"
              id="formrow-country-Input"
              placeholder="Select Country"
              options={countryOptions}
              value={countryOptions.find((option) => option.value === formik.values.country)}
              onChange={(selectedOption) =>
                formik.setFieldValue("country", selectedOption ? selectedOption.value : "")
              }
              onBlur={formik.handleBlur}
              className={formik.touched.country && formik.errors.country ? "is-invalid" : ""}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
              }}
            />
            {formik.errors.country && formik.touched.country && (
              <FormFeedback>{formik.errors.country}</FormFeedback>
            )}
          </Col>
        </Row>
        <Row className="mb-3 justify-content-center">
          <Col md={4}>
            <Label htmlFor="formrow-language-Input">Language</Label>
            <Select
              isMulti
              name="language"
              id="formrow-language-Input"
              value={languageOptions.filter((option) => formik.values.language.includes(option.value))}
              onChange={(selectedOptions) =>
                formik.setFieldValue(
                  "language",
                  selectedOptions ? selectedOptions.map((option) => option.value) : []
                )
              }
              onBlur={formik.handleBlur}
              options={languageOptions}
              className={formik.touched.language && formik.errors.language ? "is-invalid" : ""}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
              }}
            />
            {formik.errors.language && formik.touched.language && (
              <FormFeedback>{formik.errors.language}</FormFeedback>
            )}
          </Col>

          <Col md={4}>
            <Label htmlFor="formrow-timezone-Input">Timezone</Label>
            <Select
              id="formrow-timezone-Input"
              name="timezone"
              value={timezoneOptions.find((option) => option.value === formik.values.timezone)}
              onChange={(selectedOption) =>
                formik.setFieldValue("timezone", selectedOption ? selectedOption.value : "")
              }
              onBlur={formik.handleBlur}
              className={formik.touched.timezone && formik.errors.timezone ? "is-invalid" : ""}
              options={timezoneOptions}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
              }}
            />
            {formik.errors.timezone && formik.touched.timezone && (
              <FormFeedback>{formik.errors.timezone}</FormFeedback>
            )}
          </Col>
          <CustomProfileFields
            onChange={handleAdditionalProfileChange}
            fields={additionalFields}
            values={additionalProfileValues}
            errors={additionalErrors}
          />      
        </Row>
        <Row className="mb-3 justify-content-center">
          <Col md={8}>
            <Label htmlFor="formrow-linkedInProfile-Input">Linkedin Profile (optional)</Label>
            <Input
              type="text"
              name="linkedInProfile"
              id="formrow-linkedInProfile-Input"
              placeholder="Enter Linkedin Profile URL"
              value={formik.values.linkedInProfile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.linkedInProfile && formik.errors.linkedInProfile}
            />
            {formik.errors.linkedInProfile && formik.touched.linkedInProfile && (
              <FormFeedback>{formik.errors.linkedInProfile}</FormFeedback>
            )}
          </Col>

        </Row>
        <Row className="mb-3 justify-content-center">
          <Col md={8}>
            <Label htmlFor="formrow-bio-Input">Bio</Label>
            <Input
              type="textarea"
              name="bio"
              id="formrow-bio-Input"
              placeholder="Please provide a short bio"
              value={formik.values.bio}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched.bio && formik.errors.bio}
            />
            {formik.errors.bio && formik.touched.bio && (
              <FormFeedback>{formik.errors.bio}</FormFeedback>
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <button type="submit" className="btn btn-primary px-5">
            Submit
          </button>
        </div>
      </Form>
    </div>
  );
};
