import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";

import { toast } from "react-toastify";
import { saveAs } from "file-saver";

const api = new APIClient();

export const getInvitedUsers = createAsyncThunk(
  "admin/getInvitedUsers",
  async (paginationData: any) => {
    try {
      const response = await api.get(
        "api/v1/users/invited-users",
        paginationData
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const resendInvite = (id: any) => async (dispatch: any) => {
  try {
    const response = await api.create(
      `/api/v1/users/invited-users/${id}/resend-invite`,
      {}
    );
    toast.success("Resend Invite Successfully", { autoClose: 2000 });
    return response;
  } catch (error) {
    toast.error("Resend Invite Failed", { autoClose: 2000 });
    return error;
  }
};

export const deleteInvitedUser = createAsyncThunk(
  "invitedUsers/delete", 
  async (id: any, { dispatch }) => {
    try {
      const response = await api.delete(
        `/api/v1/users/invited-users/${id}`,
        {}
      );
      toast.success("Delete Invite Successfully", { autoClose: 2000 });
      return response.data; 
    } catch (error) {
      toast.error("Delete Invite Failed", { autoClose: 2000 });
      throw error;
    }
  }
);

export const getUsers = createAsyncThunk("admin/getUsers", async () => {
  try {
    const response = await api.get("/api/v1/users", null);
    return response;
  } catch (error) {
    return error;
  }
});

export const onAcceptUserRequest = createAsyncThunk(
  "admin/onAcceptUserRequest",
  async (data: any) => {
    const { userId, requestType } = data;
    try {
      const response = await api.create(`/api/v1/users/${userId}/status`, {
        status: requestType,
      });
      if (response.status) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error("Request Failed", { autoClose: 2000 });
      }
      return response;
    } catch (error: any) {
      toast.error(error.message, { autoClose: 2000 });
      throw error;
    }
  }
);

export const onStatusChange = createAsyncThunk(
  "admin/onStatusChange",
  async (data: any) => {
    const { userId, status } = data;
    try {
      const response = await api.create(
        `/api/v1/users/${userId}/status/${status}`,
        {
          status: status,
        }
      );
      if (response.status) {
        toast.success(response.message, { autoClose: 2000 });
      } else {
        toast.error("Request Failed", { autoClose: 2000 });
      }
      return response;
    } catch (error) {
      toast.error("Request Failed", { autoClose: 2000 });
      return error;
    }
  }
);

export const fetchUsers = createAsyncThunk(
  "admin/fetchUsers",
  async (userData: {
    type: string;
    value: string;
    perPage: number;
    page: number;
  }) => {
    try {
      const params = new URLSearchParams({
        status: userData.value,
        perPage: userData.perPage.toString(),
        page: userData.page.toString(),
      });

      const response = await api.get(`/api/v1/users?${params.toString()}`);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const addNewUser = createAsyncThunk(
  "admin/addNewUser",
  async (user: any, { rejectWithValue }) => {
    try {
      const response = await api.create("/api/v1/onboarding/invite", user);
      toast.success("Invite Sent Successfully", { autoClose: 3000 });
      return response;
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || error.message || "Sending Invite Failed. Please try again later.";

      toast.error(errorMessage, { autoClose: 3000 });
      return rejectWithValue(errorMessage);
    }
  }
);

export const uploadCsv = createAsyncThunk(
  "admin/uploadCsv",
  async (file: File, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await api.uploadcsvFile(
        "/api/v1/onboarding/upload/csv",
        formData
      );
      toast.success("file uploaded successfully", { autoClose: 3000 });

      return response;
    } catch (error) {
      toast.error("File upload unsuccessful.Please try again later.", {
        autoClose: 3000,
      });
      return rejectWithValue(error);
    }
  }
);

export const downloadCsv = createAsyncThunk(
  "admin/downloadCsv",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/api/v1/onboarding/template/csv", {
        responseType: "blob",
      });
      const blob = new Blob([response], { type: "text/csv" });
      saveAs(blob, "import_template.csv");
      return response;
    } catch (error) {
      toast.error("something went wrong", {
        autoClose: 3000,
      });
      return rejectWithValue(error);
    }
  }
);

export const getOrgSessions = createAsyncThunk(
  "admin/getOrgSessions",
  async (filterData: any) => {
    try {
      const { filterQuery, perPage, page } = filterData;
      filterQuery["perPage"] = perPage;
      filterQuery["page"] = page;
      const response = await api.get(`/api/v1/org-sessions`, filterQuery);
      return response;
    } catch (error: any) {
      toast.error("Failed to fetch org sessions. Please try again");
      throw error;
    }
  }
);
