import Flatpickr from "react-flatpickr";
import { Button, Col, Label, Row } from "reactstrap";
import { format, parse } from "date-fns";
import dayjs from "dayjs";
import {useEffect, useMemo, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { getSlots } from "slices/mentor/thunk";
import { rescheduleRequest, rescheduleSession } from "slices/session/thunk";
import { SESSION_OR_REQUEST_RESCHEDULED } from "../helpers/events";
import Emitter from "../helpers/emitter";
import {getMissingDates} from "../helpers/date";

const RescheduleCalendar = (props: any) => {
  const {
    mentorId,
    rescheduleType,
    isMentor,
    requestId,
    fetchSlots,
    toggle,
    toggleRescheduleModal,
  } = props;

  const [availableTime, setAvailableTime] = useState<any>([]);
  const [selectedTime, setSelectedTime] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [bookingEnabled, setBookingEnabled] = useState<boolean>(false);
  const [rescheduleReason, setRescheduleReason] = useState("");
  const [fetchedSlots, setFetchedSlots] = useState<any>([])
  const dispatch = useDispatch<any>();
  const fp = useRef(null);

  const handleInputChange = (event: any) => {
    setRescheduleReason(event.target.value);
    setBookingEnabled(selectedTime && event.target.value.length !== 0);
  };
  const handleDateChange = (date: any) => {
    const formattedDate = format(date[0], "yyyy-MM-dd");
    setSelectedDate(formattedDate);
    setBookingEnabled(true);

    if (!isMentor) {
      const daySlots = fetchedSlots.find(fetchSlotsForDay => fetchSlotsForDay.date === formattedDate);
      const time = daySlots?.slots?.[0];
      if (time) {
        const convertedTime = time.start;
        setSelectedTime(convertedTime);
      } else {
        setSelectedTime(null);
      }
      setAvailableTime(daySlots?.slots || []);
    }
  };

  const fetchSlotsForMonth = useMemo(() => {
    return (from, to) => {
      if (isMentor) {
        return
      }
      dispatch(getSlots({ from, to, userId: mentorId }))
        .then((fetchedSlots) => {
          const firstDay = fetchedSlots['availableSlots'][0];
          const time = firstDay?.slots?.[0];
          if (time) {
            const convertedTime = time.start;
            setSelectedTime(convertedTime);
            setAvailableTime(firstDay.slots);
            setSelectedDate(firstDay.date);
            if (fp.current) {
              // @ts-ignore
              fp.current.flatpickr.setDate(firstDay?.date)
            }
          } else {
            setSelectedTime(null);
            setSelectedDate(null);
            setAvailableTime([])
          }
          setFetchedSlots(fetchedSlots['availableSlots']);
        });
    };
  }, [dispatch, mentorId, isMentor]);

  const handleMonthChange = (__, ___, calendarConfig) => {
    const currentMonth = dayjs().month()
    let fromDate = dayjs().month(calendarConfig.currentMonth).year(calendarConfig.currentYear).startOf('month').format('YYYY-MM-DD')
    if (currentMonth === calendarConfig.currentMonth) {
      fromDate = dayjs().format('YYYY-MM-DD')
    }
    const toDate = dayjs().month(calendarConfig.currentMonth).year(calendarConfig.currentYear).endOf('month').format('YYYY-MM-DD')
    fetchSlotsForMonth(fromDate, toDate)
  }

  const convertTo12HourFormat = (time: string) => {
    const parsedTime = parse(time, "HH:mm", new Date());
    return format(parsedTime, "hh:mm a");
  };

  const handleBookSession = async () => {
    const formattedDate = format(new Date(selectedDate), "yyyy-MM-dd");
    const payload = {
      requestId,
      date: formattedDate,
      time: selectedTime,
      reason: rescheduleReason,
    };
    
    try {
      if (rescheduleType === "REQUESTED") {
        await dispatch(rescheduleRequest(payload));
      } else {
        await dispatch(rescheduleSession(payload));
        fetchSlots();
      }
      toggle();
      toggleRescheduleModal();
      Emitter.emit(SESSION_OR_REQUEST_RESCHEDULED, {})
    } catch (error) {
      console.error("Reschedule Error:", error);
    }
  };

  useEffect(() => {
    const today = dayjs().format('YYYY-MM-DD')
    const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');
    fetchSlotsForMonth(today, endOfMonth);
  }, [fetchSlotsForMonth])

  const datesThatHaveSlots = fetchedSlots.map(fetchSlot => fetchSlot.date)
  let missingDates: string[] = []
  if (fp.current && !isMentor) {
    // @ts-ignore
    const currentYear = fp.current.flatpickr.currentYear
    // @ts-ignore
    const currentMonth = fp.current.flatpickr.currentMonth
    missingDates = getMissingDates(datesThatHaveSlots, currentYear, currentMonth + 1)
  }

  return (
    <Row>
         
      <Col className={'mb-4' } lg={6} style={{ textAlign: "left" }}>
        <div className="d-flex justify-content-center align-items-center">
        <Flatpickr
          className="form-control self-center"
          ref={fp}
          options={{
            inline: true,
            dateFormat: "Y-m-d",
            minDate: format(new Date(), "yyyy-MM-dd"),
            disable: missingDates,
            onChange: handleDateChange,
            defaultDate: selectedDate,
            onMonthChange: handleMonthChange,
          }}
          style={{ display: "none" }}
          />
          </div>
        {!isMentor && (
          <div>
            <Label className="mt-3 mb-0">Available time slots</Label>
            <div className="mb-1">
              <hr
                style={{ border: "0.1px solid #e3e3e3", margin: "5px 0px" }}
              />
            </div>
          </div>
        )}
        {isMentor ? (
          <>
            <Row style={{ alignItems: "center" }}>
              <Col xl={12} sm={12}>
                <Flatpickr
                  className="form-control mt-3"
                  placeholder="Select Time"
                  value={selectedTime}
                  onChange={(time) => {
                    setSelectedTime(dayjs(time[0]).format("H:mm"));
                    setBookingEnabled(rescheduleReason.length !== 0);
                  }}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "H:i",
                  }}
                  style={{
                    width: "100%",}}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            {availableTime.length ? (
              availableTime.map((slotData, index) => (
                <Col key={index} xl={3} sm={6}>
                  <div className="mb-3">
                    <label className="card-radio-label mb-2">
                      <input
                        type="radio"
                        name="time"
                        className="card-radio-input"
                        value={slotData.start}
                        defaultChecked={index === 0}
                        onChange={() => setSelectedTime(slotData.start)}
                      />
                      <div className="card-radio">
                        <div className="d-flex justify-content-center align-items-center">
                          <span>{convertTo12HourFormat(slotData.start)}</span>
                        </div>
                      </div>
                    </label>
                  </div>
                </Col>
              ))
            ) : (
              <span className="d-block">No slots available</span>
            )}
          </Row>
        )}
      </Col>
      <Col lg={6}>
        <input
          type="text"
          value={rescheduleReason}
          onChange={handleInputChange}
          placeholder="Reason for rescheduling"
          style={{ height: "80px", width: "100%" }}
          className="form-control"
        />
        <Col>
        <div className="d-flex mt-3 justify-content-end align-items-center">
          <Button
            color="primary"
            onClick={handleBookSession}
            disabled={!bookingEnabled || !selectedTime || !rescheduleReason}
            className="me-3"
            >
            Reschedule
          </Button>
          <Button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              toggle();
              toggleRescheduleModal();
            }}
            >
            Close
          </Button>
        </div>
              </Col>
      </Col>
    </Row>
  );
};
export default RescheduleCalendar;