import { toast } from "react-toastify";
import { APIClient } from "../../../src/helpers/api_helper";
import { setAllMentors, setMentorSubmittedSurvey } from "./reducer";
import {ONBOARDING, USER_PROFILE_STATUS} from "../../Components/constants/userData";
import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const api = new APIClient();

export const setSlots = (data: any, onProfilePage: boolean) => async (dispatch: any) => {
    try {
    const response = await api.create('/api/v1/my/availability-slots', data);

      if (response) {
        toast.success("Slots saved successfully", { autoClose: 2000 });
        if (!onProfilePage) {
          await api.create(`/api/v1/user-config/${ONBOARDING}`, {
          mode: 'UPDATE',
            value: {
            [USER_PROFILE_STATUS.availability_set]: true
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
}

 
export const getAllMentors = createAsyncThunk(
  "mentor/getAllMentors",
  async (paginationData: { page: number; perPage: number }) => {
    try {
      const { page, perPage } = paginationData;
      const response = await api.get(
        `api/v1/search?page=${page}&perPage=${perPage}&filter.profileType=Mentor`
      );
      return response;
    } catch (error) {
      console.error("Error fetching mentors", error);
      toast.error("Failed to fetch mentors data");
      throw error;
    }
  }
);

export const fetchSuggestedMentors = createAsyncThunk(
  "mentor/fetchSuggestedMentors",
  async ({ paginationData, userType }: { paginationData: { page: number; perPage: number }; userType: string }) => {
    try {
      const { page, perPage } = paginationData;
      const response = await api.get(
        `/api/v1/search/suggested?userType=${userType}&page=${page}&perPage=${perPage}`
      );
      return response; 
    } catch (error) {
      console.error("Error fetching suggested mentors", error);
      toast.error("Failed to fetch suggested mentors data");
      throw error;  
    }
  }
);

export const filterMentors = (data: any) => async (dispatch: any) => {
  try {
    const { filterType, filterValue, paginationData } = data;
    const response = await api.get('/api/v1/search', { [`filter.${filterType}`]: filterValue,
    ...paginationData,
    });
    if (response) {
      dispatch(setAllMentors(response));
    }
  } catch (error) {
    console.error("Error while fetching filtered mentors", error);
    toast.error("Failed to fetch filtered mentors data");
  }
}

export const applyMultipleFilters = (data: any, paginationData: { page: number; perPage: number }) => async (dispatch: any) => {
  try {
    const { filterTypes, filterValues } = data;
    const newFilterTypes = [...filterTypes, 'profileType'];
    const newFilterValues = [...filterValues, 'Mentor'];
    const queryString = newFilterTypes
      .map((filterType, index) => `filter.${filterType}=${newFilterValues[index]}`)
      .join('&');
    const paginationQuery = `page=${paginationData.page}&perPage=${paginationData.perPage}`;

    const url = `/api/v1/search?${queryString}&${paginationQuery}`;
    const response = await api.get(url);

    if (response) {
      dispatch(setAllMentors(response));
    }
  } catch (error) {
    console.error("Error applying filters:", error);
  }
};


export const getMatchedMentors = (paginationData: any) => async (dispatch: any) => {
  try {
    const data = await api.get('/api/v1/search/matched', {
      'filter.profileType': 'Mentor',
      ...paginationData,
    });
    if (data) {
      dispatch(setAllMentors(data));
    }
  } catch (error) {
    console.error("Error while fetching matched mentors", error);
  }
}

export const fetchMentorSessions = (id: any) => async (dispatch: any) => {
  try {
    const temp = "9adb59b5-6fac-4232-8b25-21bb5e1e0910"
    const data = await api.get(`/api/v1/users/${temp}/availability-slots`, null);

    if (data) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getSlots = (data: any) => async (dispatch: any) => {
  const { userId, from, to } = data;
  try {
    const response = await api.get(`/api/v1/users/${userId}/slots`, {
      from: from,
      to: to
    });
    if (response) {
      const now = dayjs();
      const currentDate = now.format('YYYY-MM-DD');

      if (response.availableSlots.length && currentDate === response.availableSlots[0].date) {
        const currentHour = now.hour();
        const currentMinute = now.minute();
        const currentTimeInMinutes = currentHour * 60 + currentMinute;

        const filteredSlots = response.availableSlots[0].slots.filter(
          (slot) => {
            const [hour, minute] = slot.start.split(':').map(Number);
            const slotTimeInMinutes = hour * 60 + minute;
            return slotTimeInMinutes >= currentTimeInMinutes;
          }
        );

        response.availableSlots[0].slots = filteredSlots;
      }

      return response;
    } else {
      throw new Error("Failed to fetch slots");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const fetchMentorSubmittedSurvey = () => async (dispatch: any) => {
  try {
    const data = await api.get("/api/v1/onboarding/profile/mentor", {});
    if (data.status) {
      dispatch(setMentorSubmittedSurvey(data.profile));
    }
  } catch (error) {
    console.log(error);
  }
}

export const fetchDepartmentsAndDesignations = createAsyncThunk(
  "mentor/fetchDepartmentsAndDesignations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/v1/org-configs', {
        keys: "designations,departments",
      });
      const fetchedDepartments = response.departments.map((department: any) => ({
        label: department.title,
        value: department.value,
      }));
      const fetchedDesignations = response.designations.map((designation: any) => ({
        label: designation.title,
        value: designation.value,
      }));
      return { fetchedDepartments, fetchedDesignations };
    } catch (error) {
      toast.error("Failed to fetch departments and designations");
      return rejectWithValue(error);
    }
  }
);

export const fetchPersonalExperiences = createAsyncThunk(
  "mentor/fetchPersonalExperiences",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/v1/onboarding/profile/forms/mentor', null);
      const form = response.form;

      const personalExperiences = form?.pages
        ?.flatMap((page: any) =>
          page?.elements
            ?.filter((element: any) => element.name === "my-personal-experiences")
            ?.flatMap((formData: any) =>
              formData?.choices?.map((choice: any) => ({
                label: choice.text,
                value: choice.value,
              }))
            )
        ) || [];

      return { personalExperiences }; 
    } catch (error) {
      toast.error("Failed to fetch personal experiences");
      return rejectWithValue(error);
    }
  }
);

