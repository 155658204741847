import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import userAvatar from "../../assets/images/users/user_profile.svg";

interface ListProps {
  listData?: {
    id: string;
    matchPercentage?: number;
    avatarUrl?: string;
    firstName: string;
    lastName: string;
    department?: string;
    title?: string;
    location?: string;
    skills?: string[];
  }[];
}

const List: React.FC<ListProps> = ({ listData = [] }) => {
  return (
    <Row>
      {listData.map((item, key) => (
        <Col xl={3} key={key}>
          <Card>
            <CardBody>
              {item.matchPercentage && (
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span className="badge badge-soft-info text-uppercase">
                      {Math.round(item.matchPercentage)}% Match
                    </span>
                  </div>
                </div>
              )}
              <div className="text-center mb-3">
                <img
                  src={item.avatarUrl || userAvatar}
                  alt={`${item.firstName} ${item.lastName}`}
                  className="avatar-xl rounded-circle"
                />
                <h6 className="font-size-15 mt-3 mb-1">
                  {item.firstName} {item.lastName}
                </h6>
                <div className="text-muted">
                  {item.department && (
                    <p className="badge badge-soft-info mb-0 text-muted text-capitalize me-2 text-wrap text-break">
                      {item.department}
                    </p>
                  )}
                  {item.title && (
                    <p className="badge badge-soft-info mb-0 text-muted text-uppercase text-wrap text-break mt-1">
                      {item.title}
                    </p>
                  )}
                </div>
              </div>

              {item.location && (
                <div className="d-flex mb-1 justify-content-center gap-2 text-muted">
                  <div>
                    <i className="bx bx-map align-middle text-primary"></i> {item.location}
                  </div>
                </div>
              )}

              {item.skills && item.skills.length > 0 && (
                <div className="hstack gap-2 justify-content-center">
                  {item.skills.map((subItem, subKey) => (
                    <span key={subKey} className="badge badge-soft-secondary">
                      {subItem}
                    </span>
                  ))}
                </div>
              )}

              <div className="mt-2 pt-1">
                <Link
                  to={`/profile/${item.id}?viewingAs=MENTEE`}
                  className="btn btn-soft-primary d-block"
                >
                  View Profile
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default List;
