import { Col, FormFeedback, Label, Row } from "reactstrap";
import Select from "react-select";

interface Field {
  type: string;
  label: string;
  required?: boolean;
}

interface DropdownField extends Field {
  type: "dropdown";
  options: { value: string | number; label: string }[];
}

type AdditionalFields = Record<string, Field>;

const CustomProfileFields = ({ onChange, fields, values, errors }) => {

  const renderField = (fieldKey: string, fieldData: Field) => {
    switch (fieldData.type) {
      case "dropdown":
        const dropdownField = fieldData as DropdownField;
        return (
          <div key={fieldKey}>
            <Label for={fieldKey}>{dropdownField.label}</Label>
            <Select
              id={fieldKey}
              options={dropdownField.options}
              placeholder={`Select ${fieldData.label}`}
              isClearable
              value={dropdownField.options.find(option => option.value === values[fieldKey])}
              onChange={(selectedOption) => onChange(selectedOption?.value, fieldKey)}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: "white",
                }),
              }}
              className={errors && errors[fieldKey] ? "is-invalid" : ""}
            />
           {errors && errors[fieldKey] && <FormFeedback>{errors[fieldKey]}</FormFeedback>}
          </div>
        );
      case "text":
        return <div />;
      case "number":
        return <div />;
      default:
        return null;
    }
  };

  const fieldKeys = Object.keys(fields) as Array<keyof AdditionalFields>;

  const groupedFields: string[][] = [];
  for (let i = 0; i < fieldKeys.length; i += 2) {
    groupedFields.push(fieldKeys.slice(i, i + 2));
  }

  return (
    <div>
      {groupedFields.map((group, index) => (
        <Row key={index} className="mt-3 justify-content-center">
          {group.map((fieldKey) => (
            <Col md={4} key={fieldKey}>
              {renderField(fieldKey, fields[fieldKey])}
            </Col>
          ))}
          {group.length === 1 && <Col md={4} key="empty" />}
        </Row>
      ))}
    </div>
  );
};

export default CustomProfileFields;
